import React from 'react';
import logo from '../assets/img/logo.png'
import {
    Link
} from "react-router-dom";

function Navbar(props) {
    const logout = () => {
        props.authorized(null, null);
    }

    return (
        <div className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse"
                    aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <a className="navbar-brand" href="index.html">
                    <img src={logo} className="navbar-brand-img 
              mx-auto" alt="..." />
                </a>
                <div className="navbar-user d-md-none">
                    <ul className="navbar-nav mb-md-4">
                        <li className="nav-item">
                            <button onClick={logout} className="navbar-user-link btn">
                                <i className="fe fe-log-out"></i> Logout
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="collapse navbar-collapse" id="sidebarCollapse">

                    <ul className="navbar-nav mb-md-4">
                    <li className="nav-item">
                            <Link className="nav-link " to="/ads">
                                <i className="fe fe-dollar-sign"></i> Ads
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link " to="/apps">
                                <i className="fe fe-smartphone"></i> Apps/Services
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link " to="/links">
                                <i className="fe fe-link"></i> Links
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link " to="/featured">
                                <i className="fe fe-star"></i> Featured businesses
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link " to="/tokens">
                                <i className="fe fe-box"></i> Verified Tokens
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link " to="/admins">
                                <i className="fe fe-user"></i> Admins
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link " to="/apikeys">
                                <i className="fe fe-code"></i> API keys
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link " to="/articles">
                                <i className="fe fe-edit"></i> Articles
                            </Link>
                        </li>
                    </ul>

                    <div className="mt-auto"></div>

                    <div className="navbar-user d-none d-md-flex" id="sidebarUser">
                        <div className="avatar avatar-sm avatar-online">
                            <img src={props.account.picture} className="avatar-img rounded-circle" alt="profile" />
                        </div>
                        <button onClick={logout} className="navbar-user-link btn">
                            <i className="fe fe-log-out"></i> Logout
                    </button>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default Navbar;