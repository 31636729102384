import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Navbar from './components/Navbar'
// import Dashboard from './scenes/dashboard';
// import Businesses from './scenes/businesses';
import Links from './scenes/links';
import Form from './scenes/links/form';
import Apps from './scenes/apps';
import Admins from './scenes/admins';
import ApiKeys from './scenes/apikeys'
import AppForm from './scenes/apps/AppForm'
import FeaturedBusinessForm from './scenes/featuredbusinesses/form';
import FeaturedBusinesses from './scenes/featuredbusinesses';
import TokenForm from './scenes/tokens/form'

import Tokens from './scenes/tokens';
import Login from './scenes/login';
import * as Account from './account';
import Ads from './scenes/ads';
import AdForm from './scenes/ads/AdForm'
import Articles from './scenes/articles';
import ArticleForm from './scenes/articles/ArticleForm';

function App(props) {

  const [account, updateAccount] = useState(Account.getUserAccount());

  function authorized(googleAccount, idToken) {
    if (idToken !== null) {
      googleAccount.idToken = idToken;
    }
    sessionStorage.setItem(Account.accountSessionKey, JSON.stringify(googleAccount))
    updateAccount(googleAccount);
  }

  if (account === null || account === undefined) {
    return (<Login authorized={authorized}></Login>);
  }

  return (
    <Router>
      <Navbar authorized={authorized} account={account}></Navbar>
      <div className="main-content">
        <Switch>
        <Route path="/articles/:id" component={ArticleForm}></Route>
          <Route path="/articles">
            <Articles />
          </Route>
        <Route path="/apikeys">
            <ApiKeys />
          </Route>
          <Route path="/admins">
            <Admins />
          </Route>
          <Route path="/ads/:id" component={AdForm}></Route>
          <Route path="/ads">
            <Ads />
          </Route>
          
          <Route path="/apps/:id" component={AppForm}></Route>
          <Route path="/apps">
            <Apps />
          </Route>
          <Route path="/links/:id" component={Form}></Route>
          <Route path="/links">
            <Links />
          </Route>
          <Route path="/featured/:id" component={FeaturedBusinessForm}></Route>
          <Route path="/featured">
            <FeaturedBusinesses />
          </Route>
          <Route path="/tokens/:id" component={TokenForm}></Route>
          <Route path="/tokens">
            <Tokens />
          </Route>
          <Route exact path="/">
            <Links />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
