import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import {Link} from "react-router-dom";
import arrayMove from 'array-move';

export default function List(props) {
    const [data, setData] = React.useState({ list: [] });

    React.useEffect(() => {
        setData({ list: props.list })
    }, [props.list]);


    function parseTimestamp(unixTimestamp) {
        var date = new Date();

        if (unixTimestamp !== 0) {
            date = new Date(unixTimestamp * 1000);
        }

        return date.toUTCString()
    }

    const SortableItem = SortableElement(({ item, last }) => (
        <div className="col col-12 col-lg-12 mx-auto">
            <div className="card">
                {
                    props.editSorting ?
                    null
                    :
                    <img src={item.featured_image_url} className="card-img-top" alt={item.title} />
                }
                
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col">
                        
                            <h4 className="card-title mb-2 name">
                            <Link key={item.id} to={`/featured/${item.id}`}>{item.title}</Link> {
                        item.is_active ?
                            <span className="badge badge-soft-primary text-uppercase">Active</span>
                            :
                            <span className="badge badge-soft-secondary text-uppercase">Inactive</span>
                    }
                            </h4>
                            <p className="card-text small text-secondary mb-2">
                                {item.description}
                            </p>
                            <p className="card-text small text-info">
                                <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a>
                            </p>
                            <p className="card-text small text-muted">{ parseTimestamp(item.time.from)} – {parseTimestamp(item.time.to)}</p>
                        </div>
                        <div className="col-auto">
                        
                        
                        {
                        props.editSorting ?
                        <button className="btn text-muted"><span className="fe fe-align-justify"></span></button>
                        :
                        <Link key={item.id} className="btn btn-light btn-sm" to={`/featured/${item.id}`}>edit</Link>
                    }

                        </div>
                    </div>
                </div>
            </div>
        </div>

    ));

    const SortableList = SortableContainer(({ list }) => {
        return (
            <div className="row">
                <div className="col col-md-12">
            <div className="row">
                {list.map((item, index) => (
                    <SortableItem disabled={!props.editSorting} key={`item-${item.id}`} index={index} item={item} last={index === list.length - 1} />
                ))}
            </div>
            </div>
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        var list = data.list;
        list = arrayMove(list, oldIndex, newIndex);
        setData({ list: list });
        props.onSort(list)
    };

    return (
        <div>
            <SortableList distance={2} list={data.list} onSortEnd={onSortEnd}></SortableList>
        </div>
    );
}